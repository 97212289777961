export const columns = [
  {
    label: 'product',
    field: 'product.brandName',
    // formatRowValue: true,
    // formatFn: (value) => {
    //   return value.product.brandName;
    // },
    width: '70%',
    filterOptions: {
      enabled: false,
      // enabled: true,
      // placeholder: ' ',
      // filterValue: '',
    },
  },
  {
    label: 'count',
    field: 'count',
    width: '30%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
]
