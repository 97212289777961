<template>
    <div class="app flex-row align-items-center">
      <div class="container">
        <h2>{{ $t('statisticProductList') }}</h2>
        <grid-view-table
          id="statistic-product"
          :columns="columns"
          :path="'statistic-product'"
          :per-page="100"
        />
      </div>
    </div>
  </template>
  
  <script>
    import GridViewTable from '../../components/GridViewTable';
    import { columns } from './grid-views/search-products';
  
    export default {
      name: 'StatisticProductList',
      components: {
        GridViewTable,
      },
      data: () => {
        return {
          columns,
        }
      }
    }
  </script>